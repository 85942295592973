import React from 'react';
import { Input, Label } from 'reactstrap';

type textFieldProps = {
    handleInputChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    handlePersonInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    label: string,
    id: string,
    validate?: (id: string, value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
    validatePerson?: (id: string, value: string, index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    errorMsg: string,
    max?: number,
    type: 'number' | 'text' | 'password',
    index?: number,
    focusEvent?: () => void,
    otherProps?:any,
    handleKeyDownEvent?: (event: React.KeyboardEvent<HTMLInputElement> )  => void;
    preventPasteEvent?: boolean,
    spellcheck?:boolean,
    required: boolean
}

const TextField = (props: textFieldProps) => {
    const { handleInputChange, value, label, id, validate, errorMsg, max, type, validatePerson, index, focusEvent, handlePersonInputChange, otherProps,handleKeyDownEvent, preventPasteEvent, spellcheck, required  } = props;
    return (
        <div className='fieldset position-relative'>
            <Label>{label}</Label>
            <Input
                type={type}
                name={id}
                id={id}
                max={max}
                placeholder=''
                autoComplete={type === 'password' ? "new-password" : "off"}
                translate="no"
                value={value}
                spellCheck={spellcheck}
                onChange={(e) => {
                    if (handleInputChange) {
                        handleInputChange(e);
                    } else if (handlePersonInputChange) {
                        handlePersonInputChange(e);
                    }
                }}
                onKeyDown={(e) => {
                    if(handleKeyDownEvent){
                        handleKeyDownEvent(e);
                    }
                }}
                onFocus={(e) => {
                    e.target.parentElement!.classList.add('focus')
                    if (focusEvent) {
                        focusEvent()
                    }
                }
                }
                onBlur={(e) => {
                    if (e.target.value === '') {
                        e.target.parentElement!.classList.remove('focus');
                    }
                    if (validate) {
                        validate(id, value, e)
                    }
                    if (validatePerson && index !== undefined) {
                        validatePerson(id, value, index, e)
                    }

                }}
                onPaste={(e: any) => {
                    if (preventPasteEvent) {
                        e.preventDefault();
                        return false;
                    }
                  }}
                required={required}
                className='p-2 mb-2'
                {...otherProps}
            />
            {errorMsg && <span className="error">{errorMsg}</span>}
        </div>
    );
};

export default TextField;