import React, { Dispatch, useState, useEffect } from 'react';
import '../sass/header.scss'
import { fetchProvincias, fetchTextoAyuda } from '../../reducer/config-reducer'
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { KeyboardArrowDown } from '@mui/icons-material';
import { configObjectProvincias, provincia } from '../../types/types';
import AyudaModal from './AyudaModal';
import Spinner from './Spinner';
import {removeEventoSeleccionado, resetPasosCompra, removeDetalleCompra} from '../../reducer/events-reducer';
import CustomButton from '../components/Button';
import { getLangValue } from '../../api/config';

interface propiedades {
    fetchProvincias: Dispatch<void>
    fetchTextoAyuda: Dispatch<string>
    removeEventoSeleccionado: Dispatch<void>
    resetPasosCompra: Dispatch<void>
    removeDetalleCompra: Dispatch<void>
    provincias: provincia[] | null,
    textoAyuda: string,
    urlCartelera?: string,
}
const Header = (props: propiedades) => {
    const { provincias, textoAyuda, urlCartelera } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [currentPath, setCurrentPath] = useState<string>('');
    const [reloadButton, setReloadButton] = useState(false);
    const [lang] = useState<string>(`${getLangValue("lang")}`);

    const location = useLocation();
    const navigate = useNavigate();

    const handleCartelera = () => {
        props.fetchProvincias();
        setDropdownOpen((prevState) => !prevState);
    }

    const handleAyuda = () => {
        let path: string = '';
        const eventoRegex = /^\/evento\/([^/]+)\/step\/(\d+)$/;
        const match = window.location.pathname.match(eventoRegex);

        if (match) {
            const stepNumber = parseInt(match[2]);
            if (stepNumber === 1) {
                path = 'paso1';
            } else if (stepNumber === 2) {
                path = 'entradas';
            } else if (stepNumber === 3) {
                path = 'datoscomprador';
            } else if (stepNumber === 4) {
                path = 'datospago';
            }
        } else if (window.location.pathname.includes('pago-finalizado')) {
            path = 'pagoexitoso';
        } else if (window.location.pathname.includes('pago-pendiente')) {
            path = 'pagoexitoso';
        } else {
            switch (window.location.pathname) {
                case '/':
                    path = 'www';
                    break;
                case '/eventos':
                    path = 'list';
                    break;
                default:
                    break;
            }
        }

        props.fetchTextoAyuda(path)
    }

    useEffect(() => {
        setCurrentPath(location.pathname)
    }, [location]);

    const navigateEventsHandler = (provinciaId: string) => {
        navigate(`/eventos?p=${provinciaId}`)
        setDropdownOpen(false)
    }

    const limpiarHistoria = () =>{
        localStorage.removeItem('token');
        props.removeEventoSeleccionado();
        props.resetPasosCompra();
        props.removeDetalleCompra();
    }

    useEffect(()=>{
        if(textoAyuda === ''){
            setReloadButton(true);
            setTimeout(()=>{setReloadButton(false)},1000)
        }
    },[textoAyuda])

    return (
        <header className={window.location.pathname === '/eventos' ? 'fija header d-flex  align-items-center' : 'header d-flex align-items-center'}>
            <Container>
                <Row>
                    <Col className='position-relative text-start p-0' xs={3}>
                    {lang === 'es_AR' && <>
                        {currentPath === '/' && <Dropdown isOpen={dropdownOpen} toggle={handleCartelera} direction='down'>
                            <DropdownToggle className='dd-menu' tag='div'>
                                {dropdownOpen ? <CloseIcon color="secondary" className='ms-2 close-icon' /> : <Button variant='outlined' color='primary' className='btn-abrir-cartelera ms-2'>
                                    <span>Ver Cartelera</span>
                                </Button>}
                            </DropdownToggle>
                            <DropdownMenu tag={'ul'} className='submenu'>
                                {!provincias && <li className='spinner-wrapper'><Spinner /></li>}
                                {provincias && <li className='ps-2 mb-2 submenu-title'><strong>VER CARTELERA de <KeyboardArrowDown /></strong></li>}
                                {provincias?.map((provincia) => {
                                    return (
                                        <li className='ps-2 mb-2 pe-2' key={provincia.id} onClick={() => { navigateEventsHandler(provincia.id) }}>{provincia.name}</li>
                                    )
                                })
                                }
                            </DropdownMenu>
                        </Dropdown>}
                        </>}
                        {lang === 'es_ES' && currentPath === '/' && <>
                            <Button variant='outlined' color='primary' className='btn-abrir-cartelera ms-2' onClick={() => { navigateEventsHandler("")}}>
                                <span>Ver Cartelera</span>
                            </Button>
                        </>}
                        {urlCartelera && currentPath !== '/' && <>
                            <Link to={urlCartelera}>
                                <Button variant='outlined' color='primary' className='btn-abrir-cartelera ms-2' style={{"marginTop": "10px"}}>
                                    <span>Ver Cartelera</span>
                                </Button>
                            </Link>
                        </>}
                    </Col>
                    <Col xs={6} className='text-center'>
                        {currentPath !== '/' && <Link to='/' onClick={limpiarHistoria}><img src="/images/logo.png" alt="logo entrada web" className='img-fluid mb-2 logo' /></Link>}
                    </Col>
                    <Col className='text-end d-flex justify-content-end align-items-center' xs={3}>
                        <div style={{"display" : window.location.pathname.includes('productores-y-salas') ? "none" : "block"}}>
                            <CustomButton className='btn-ayuda' onClick={handleAyuda} reloadButton={reloadButton}>AYUDA</CustomButton>
                        </div>
                    </Col>
                </Row>
            </Container>
            {textoAyuda !== '' && <AyudaModal />}
        </header>
    );
};

const mapStateToProps = (state: configObjectProvincias) => {
    return {
        provincias: state.configReducer.provincias,
        textoAyuda: state.configReducer.textoAyuda
    }
}
export default connect(
    mapStateToProps,
    { fetchProvincias, fetchTextoAyuda,removeEventoSeleccionado, resetPasosCompra, removeDetalleCompra }
)(Header);
