import { ApiError } from "./ApiError";
import { Client } from "./baseurl";
const basePath = '/purchase'

export const comprarPasoDos = async (code: string, seller: string, referrer: string | null, cardNumber: string | null) => {
  const request = {
    "sessionEventId": code,
    "seller": seller,
    "referrer": referrer,
    "cardNumber": cardNumber
  }
  const token = await getToken();
  const headers = {
    token: token,
  };
  try {
    const response = await Client.post(`${basePath}/step-two`, request, { headers });
    return response.data;  
  } catch (error:any) {
    // Aquí manejas el error del servidor
    let message = "Ocurrio un error";
    let details = undefined;
    if (error!.response!.data!.message) {
      message = error!.response!.data!.message;
      details = error!.response!.data!.details;
    }
    throw new ApiError(message, details);
  }
  
}

export const getPrices = async (sectorCode: string) => {
  const token = await getToken();
  const headers = {
    token: token,
  };

  const request = {};

  const response = await Client.post(`${basePath}/get-prices?sectorCode=${sectorCode}`, request, { headers });
  return response.data;
}

export const purchaseSell = async (code: string, selectedPrice?: number) => {
  const request = {
    "sectorCode": code,
    "selectedPrice": selectedPrice ? selectedPrice : '0',
  }
  const token = await getToken();
  const headers = {
    token: token,
  };

  const response = await Client.post(`${basePath}/sell`, request, { headers });
  return response.data;

}

export const getPurchaseData = async(code: string) => {
  try {
      const response = await Client.get(`/payment/${code}`);
      return response.data;    
  } catch (error:any) {
      let message = "Ocurrio un error";
      let details = undefined;
      if (error!.response!.data!.message) {
          message = error!.response!.data!.message;
          details = error!.response!.data!.details;
      }
      throw new ApiError(message, details);
  }
}


export const purchaseDeleteDetail = async (detailCode: string | null) => {
  const request = {
    "purchaseProgressDetailCode": detailCode,
  }
  const token = await getToken();
  const headers = {
    token: token,
  };

  const response = await Client.post(`${basePath}/delete-detail`, request, { headers });
  return response.data;

}

export const purchaseInfo = async () => {
  const token = await getToken();
  const headers = {
    token: token,
  };
  const response = await Client.get(`${basePath}/info`, { headers });
  return response.data;
}

export const seatWidgetInfo = async () => {
  const token = await getToken();
  const headers = {
    token: token,
  };
  const response = await Client.get(`${basePath}/seat-widget-info`, { headers });
  return response.data;
}

export const getPromotionCode = async (promotion: string) => {
  const token = await getToken();
  const headers = {
    token: token,
  };
  const response = await Client.get(`promotions?promotionValue=${encodeURI(promotion)}`, { headers });
  return response.data;
}

type buyerObject = {
  firstName: string,
  lastName: string,
  email: string,
  emailVerification: String,
  phone: number,
  province: string,
  locality: string,
  numberIdentity: number | null,
  businessName: string | null,
  bookingCode: string | null,
  sellerName: string | null,
  termsConditions:boolean | false,
  buyersTicket: [
    {
      firstName: string,
      lastName: string,
      email: string,
      numberIdentity: number | null,
    }
  ] | null,
  additionalFields:{} | null

}

export const comprarPasoTres = async (buyerObject: buyerObject, paymentType: string) => {
  const request = buyerObject;
  const token = await getToken();
  const headers = {
    token: token,
  };

  try {
    const response = await Client.post(`${basePath}/step-three/${paymentType}`, request, { headers });  
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Error en la respuesta del servidor");
    }
  } catch (error:any) {
    // Aquí manejas el error del servidor
    let message = "Ocurrio un error";
    let details = undefined;
    if (error!.response!.data!.message) {
      message = error!.response!.data!.message;
      details = error!.response!.data!.details;
    }
    throw new ApiError(message, details);
  }
  
}

export const validateDni = async (dni: string) => {
  const token = await getToken();
  const headers = {
    token: token,
  };
  const response = await Client.get(`${basePath}/buyer/validate-dni?dni=${dni}`, { headers });
  return response.data;
}

export const validateEmail = async (email: string) => {
  const token = await getToken();
  const headers = {
    token: token,
  };
  const response = await Client.get(`${basePath}/buyer/validate-email?email=${email}`, { headers });
  return response.data;
}

export const getBuyerData = async () => {
  const token = await getToken();
  const headers = {
    token: token,
  };
  const response = await Client.get(`${basePath}/buyer`, { headers });
  return response.data;
}

export const sendCardNumber = async (cardNumber: string) => {
  const token = await getToken();
  const headers = {
    token: token,
  };
  const response = await Client.get(`/payment/${cardNumber}`, { headers });
  return response.data;
}

export const checkCardNumber = async () => {
  const token = await getToken();
  const headers = {
    token: token,
  };
  const response = await Client.get(`/payment/check-card`, { headers });
  return response.data;
}

export const payCash = async (numberIdentity:string, cashType:string) => {
  const request = {
    "numberIdentity": numberIdentity,
    "cashType": cashType,
  }
  const token = await getToken();
  const headers = {
    token: token,
  };

  const response = await Client.post(`payment/cash`, request, { headers });
  return response.data;
}

export const proccessPaymentMercadoPago = async(formData:any) => {
  const token = await getToken();
  const headers = {
    token: token,
  };

  const response = await Client.post(`payment/mp/process`,formData, { headers });
  return response.data;
}

export const proccessPaymentRedSys = async(redSysToken:string) => {
  const token = await getToken();
  const headers = {
    token: token,
  };

  const response = await Client.post(`payment/redsys/process`,{token:redSysToken}, { headers });
  return response.data;
}

export const paymentSuccess = async(code:string) => {
  const response = await Client.get(`payment/success/${code}`);
  return response.data;
}

export const decidirPayWay = async(bin:string, othertoken:string, dni:string, installment:number) => {
  try {
    const token = await getToken();
    const headers = {
      token: token,
    };

    const request = {
      "bin": bin,
      "token": othertoken,
      "dni": dni,
      "installment": installment
    };

    const response = await Client.post(`payment/pay-way/process`, request, { headers });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Error en la respuesta del servidor");
    }
  } catch (error:any) {
    // Aquí manejas el error del servidor
    let message = "Ocurrio un error";
    let details = undefined;
    if (error!.response!.data!.message) {
      message = error!.response!.data!.message;
      details = error!.response!.data!.details;
    }
    throw new ApiError(message, details);
  }
};

export const directPay = async(dni:string, deviceType: string) => {
  const token = await getToken();
  const headers = {
    token: token,
  };
  const response = await Client.get(`/payment/directPaid?dni=${dni}&deviceType=${deviceType}`, { headers });
  return response.data;
  /* const token = await getToken();
  // Datos ficticios de respuesta
  const mockResponse = {
    endpoint: "https://sandbox.mercadopago.com.ar/checkout/v1/redirect?pref_id=235879802-fd6a4d39-a0b0-4276-8ffd-00063a76f5dd",
    id: "235879802-fd6a4d39-a0b0-4276-8ffd-00063a76f5dd",
  };
   return mockResponse;*/
}

export const redSysRedirect = async(redsysType:string) => {
  const token = await getToken();
  const headers = {
    token: token,
  };
  const response = await Client.get(`/payment/redsys/${redsysType}`, { headers });
  return response.data;
}


export const mercadoPagoValidateToken = async(token:string, paymentStatus:string, paymentId:string, paymentType:string) => {

  const request = {
    "paymentStatus": paymentStatus,
    "paymentId": paymentId,
    "paymentType": paymentType
  };

  const response = await Client.post(`payment/directPaid/validate?token=${token}`, request);
  return response.data;
}

export const redSysValidatePayment = async(dsMerchantParameters: string, dsSignature: string, dsSignatureVersion: string) => {

  const token = await getToken();
  const headers = {
    token: token,
  };

  const request = {
    "Ds_MerchantParameters": dsMerchantParameters,
    "Ds_Signature": dsSignature,
    "Ds_SignatureVersion": dsSignatureVersion
  };

  const response = await Client.post(`payment/redsys/bizum/validate`, request, {headers});
  return response.data;
}

export const sendComment = async(code:string, message:string) => {

  const request = {
    "code": code,
    "message": message
  };

  const response = await Client.post(`payment/send-comments`, request);
  return response.data;
}

const getToken = () => {
  return new Promise<string>((resolve) => {
    const token = localStorage.getItem('token');
    if (token) {
      resolve(token);
    } else {
      const checkToken = setInterval(() => {
        const newToken = localStorage.getItem('token');
        if (newToken) {
          clearInterval(checkToken);
          resolve(newToken as string); // Conversión explícita de tipo a string
        }
      }, 1000);

      setTimeout(() => {
        clearInterval(checkToken);
        resolve(''); // Devuelve una cadena vacía si el token no existe después de 1 segundo
      }, 1000);
    }
  });
};

